const apiUrl = '/api/v1/';
const apiUrlV2 = '/api/v2/';
const apiUrlV3 = '/api/v3/';
const apiUrlV4 = '/api/v4/';
const apiUrlV5 = '/api/v5/';
const apiAdminUrl = '/admin/v1/';
const apiAdminSupplierUrl = '/supplier_ci/api/v1/';
const apiAdminSupplierUrlV2 = '/supplier_ci/api/v2/';

const api: Record<string, any> = {
  getUsersList: `${apiAdminUrl}users/all`,
  createUsers: `${apiAdminUrl}users/batch`,
  changeUser: (userId: number): string => `${apiAdminUrl}users/${userId}`,
  archiveUser: (userId: number): string => `${apiAdminUrl}users/${userId}/block`,
  restoreUser: (userId: number): string => `${apiAdminUrl}users/${userId}/unblock`,
  currentUser: `${apiUrl}current-users`,
  manageCategoriesCounters: `${apiUrlV2}customers/counters`,
  // get manage categories lists
  manageAllPlants: `${apiUrlV2}plants`,
  manageAllCustomers: `${apiUrlV2}customers`,
  manageAllProjects: `${apiUrl}customers/projects`,
  // manage page search api
  manageCustomersSearch: `${apiUrlV2}customers/list-names`,
  manageWatchlistCustomersSearch: `${apiUrlV2}customers/watchlist/list-names`,
  managePlantsSearch: `${apiUrlV2}plants/list-names`,
  manageWatchlistPlantsSearch: `${apiUrlV2}plants/watchlist/list-names`,
  manageProjectSearch: `${apiUrl}customers/projects/list-names`,
  manageWatchlistProjectSearch: `${apiUrl}customers/projects/watchlist/list-names`,
  // get step through widget categories modal list
  stepThroughWidgetAllCustomers: `${apiUrlV2}customers`,
  stepThroughWidgetAllPlants: `${apiUrlV2}plants`,
  stepThroughWidgetAllProjects: `${apiUrl}customers/projects`,
  // add/remove entity from/to watchlist
  addCustomerToWatchList: `${apiUrl}watchlist`,
  removeBunchOfCustomers: `${apiUrl}watchlist`,
  removeFromCustomerWatchList: (customerId: number): string => `${apiUrl}watchlist/${customerId}`,
  addPlantToWatchList: `${apiUrl}plant/watchlist`,
  removeFromPlantWatchList: (plantId: number): string => `${apiUrl}plant/watchlist/${plantId}`,
  removeBunchOfPlants: `${apiUrl}plant/watchlist`,
  addProjectToWatchList: `${apiUrl}customers/projects/watchlist`,
  removeFromProjectWatchList: `${apiUrl}customers/projects/watchlist`,
  removeBunchOfProjects: `${apiUrlV2}customers/projects/watchlist`,
  // categories details
  getCustomersWatchlist: `${apiUrlV3}customers`,
  getPlantsWatchlist: `${apiUrlV3}plants`,
  getProjectWatchlist: `${apiUrlV2}customers/projects/tonnages`,
  // material details:
  getCustomerMaterialDetails: (customerId: number | null): string =>
    `${apiUrl}customers/${customerId}/materials/details`,
  getPlantMaterialDetails: (plantId: number | null): string =>
    `${apiUrl}plant/${plantId}/materials/details`,
  getProjectMaterialDetailsPlantType: (plantId: number | undefined): string => `${apiUrl}plant/${plantId}/projects/materials/details`,
  getProjectMaterialDetailsCustomerType: (customerId: number | undefined): string => `${apiUrl}customers/${customerId}/projects/materials/details`,
  getEntityName: (entityId: number): string => `${apiUrl}customers/${entityId}/name`,
  // features flags
  getFeaturesList: `${apiUrl}features`,
  addNewFeatureFlag: `${apiUrl}features/frontend-only`,
  deleteFeatureFlag: (uid: string): string => `${apiUrl}features/${uid}/frontend-only`,
  enableFeatureFlag: (uid: string): string => `${apiUrl}features/${uid}/enable`,
  disableFeatureFlag: (uid: string): string => `${apiUrl}features/${uid}/disable`,
  // project plus feature
  getGeneralSettingsProjectPlus: `${apiUrl}projects-plus/general-settings`,
  updateGeneralSettingsProjectPlus: `${apiUrl}projects-plus/general-settings`,
  getConstantsForProjectPlus: `${apiUrlV3}projects-plus/project-completion/constants`,
  getRounds: `${apiUrlV4}projects-plus/rounds`,
  getTrafficValuesForLapTrackChart: `${apiUrlV5}projects-plus/traffic/grouped-tonnages`,
  getTrafficPercentages: `${apiUrlV5}projects-plus/traffic/tonnage-percentages`,
  getProjectPlusRecentlyViewed: `${apiUrl}projects-plus/widgets/recently-viewed`,
  addProjectPlusRecentlyViewed: (projectName: string): string =>
    `${apiUrl}projects-plus/widgets/${projectName}/mark-viewed`,
  getLastDataUpdatingTime: `${apiUrlV2}jobs/statistics`,
  // project plus widget
  getTrackedProjectPlusList: `${apiUrlV5}projects-plus/widgets/tracked`,
  getUntrackedProjectPlusList: `${apiUrlV5}projects-plus/widgets/untracked`,
  getHistoricalProjectPlusList: `${apiUrlV5}projects-plus/widgets/historical`,
  mutateProjectPlus: `${apiUrlV5}projects-plus/widgets`,
  getProjectPlusCounters: `${apiUrlV5}projects-plus/widgets/counts`,
  getProjectPlusOne: `${apiUrlV5}projects-plus/widgets/one`,
  getHistoryChangeLogs: `${apiUrlV3}projects-plus/audit-logs`,
  getWidgetsOverview: `${apiUrlV3}projects-plus/widgets-overview`,
  addProjectPlusToHiddenList: `${apiUrlV3}projects-plus/hidden-projects`,
  removeProjectPlusFromHiddenList: `${apiUrlV3}projects-plus/hidden-projects`,
  getHiddenProjectPlusList: `${apiUrlV3}projects-plus/hidden-projects`,
  // custom widgets
  getAllWidgets: `${apiUrlV2}widgets`,
  getCustomWidget: (widgetId: number): string => `${apiUrlV2}widgets/${widgetId}`,
  getTopProjectsListCustomers: `${apiUrl}customers/rating`,
  getTopProjectsListPlants: `${apiUrl}plant/rating`,
  createCustomersTonnageTrackerWidget: `${apiUrlV2}customers/tonnage-tracker-widgets`,
  createPlantTonnageTrackerWidget: `${apiUrlV2}plants/tonnage-tracker-widgets`,
  createProjectTonnageTrackerWidget: `${apiUrl}projects/tonnage-tracker-widgets`,
  createCustomersBucketWidget: `${apiUrlV2}customers/bucket-widgets`,
  createPlantBucketWidget: `${apiUrlV2}plants/bucket-widgets`,
  createProjectBucketWidget: `${apiUrl}projects/bucket-widgets`,
  updateCustomersTonnageTrackerWidget: (id: number): string => `${apiUrlV2}customers/tonnage-tracker-widgets/${id}`,
  updatePlantTonnageTrackerWidget: (id: number): string => `${apiUrlV2}plants/tonnage-tracker-widgets/${id}`,
  updateProjectTonnageTrackerWidget: (id: number): string => `${apiUrl}projects/tonnage-tracker-widgets/${id}`,
  updateCustomersBucketWidget: (id: number): string => `${apiUrlV2}customers/bucket-widgets/${id}`,
  updatePlantBucketWidget: (id: number): string => `${apiUrlV2}plants/bucket-widgets/${id}`,
  updateProjectBucketWidget: (id: number): string => `${apiUrl}projects/bucket-widgets/${id}`,
  updateWidget: (id: number): string => `${apiUrlV2}widgets/${id}`,
  changeCustomWidgetPosition: (widgetId: number): string =>
    `${apiUrl}widgets/${widgetId}/move-positions`,
  removeWidgetBucketLoading: (widgetId: number, recipientType: string): string =>
    `${apiUrl}${recipientType}/bucket-widgets/${widgetId}`,
  removeTonnageTrackerBucket: (widgetId: number, recipientType: string): string =>
    `${apiUrl}${recipientType}/tonnage-tracker-widgets/${widgetId}`,
  getWidgetBucket: (widgetId: number): string =>
    `${apiUrl}widgets/${widgetId}/bucket`,
  getWidgetTonnageTracker: (widgetId: number): string =>
    `${apiUrl}widgets/${widgetId}/tonnage-tracker`,
  getWidgetBucketDetailsRecipients: (widgetId: number): string =>
    `${apiUrlV2}widgets/${widgetId}/recipients/buckets/details`,
  getWidgetBucketProjectDetailsRecipients: (widgetId: number): string =>
    `${apiUrlV2}widgets/${widgetId}/recipients/buckets/project-details`,
  getWidgetTonnageTrackerDetailsRecipients: (widgetId: number): string =>
    `${apiUrlV2}widgets/${widgetId}/recipients/tonnage-tracker/details`,
  getWidgetTonnageTrackerProjectDetailsRecipients: (widgetId: number): string =>
    `${apiUrlV2}widgets/${widgetId}/recipients/tonnage-tracker/project-details/searches`,
  moveWidget: (widgetId: number): string => `${apiUrl}widgets/${widgetId}/move-positions`,
  getSelectedEntityIdsForWidget: (widgetId: number): string => `${apiUrlV2}widgets/${widgetId}/customer-ids`,
  getSelectedProjectsEntityIdsForWidget: (widgetId: number): string => `${apiUrlV2}widgets/${widgetId}/projects-ids`,
  // historical
  getDayStatistics: `${apiUrlV4}projects-plus/rounds/days-statistics`,
  getProjectsNumberPerDayStatistics: `${apiUrlV4}projects-plus/rounds/days-statistics-all`,
  // excluded slips
  excludedSlips: `${apiUrlV3}projects-plus/excluded-slips`,
  // associated entity
  getCustomersCount: (plantId: number | null): string => `${apiUrl}plant/${plantId}/customers/count`,
  getCustomerTopPlants: (entityId: number): string => `${apiUrl}customers/${entityId}/plants/top`,
  getCustomerTopProjects: (customerId: number): string => `${apiUrl}customers/${customerId}/projects/top`,
  getPlantsCount: (customerId: number | null): string => `${apiUrl}customers/${customerId}/plants/count`,
  getPlantTopCustomers: (entityId: number): string => `${apiUrl}plant/${entityId}/customers/top`,
  getPlantTopProjects: (plantId: number): string => `${apiUrl}plant/${plantId}/projects/top`,
  getCustomerPlantsStatusCount: (customerId: number): string => `${apiUrl}customers/${customerId}/plants/count-all`,
  getCustomerProjectsStatusCount: (customerId: number): string => `${apiUrl}customers/${customerId}/projects/count-all`,
  getPlantCustomersStatusCount: (plantId: number): string => `${apiUrl}plant/${plantId}/customers/count-all`,
  getPlantProjectsStatusCount: (plantId: number): string => `${apiUrl}plant/${plantId}/projects/count-all`,
  getCustomerPlantDetails: (
    customerId: number,
    plantId: number,
  ): string => `${apiUrl}customers/${customerId}/plants/${plantId}/tonnages/details`,
  getPlantCustomerDetails: (
    plantId: number,
    customerId: number,
  ): string => `${apiUrl}plant/${plantId}/customers/${customerId}/tonnages/details`,
  getCustomerProjectDetails: (customerId: number): string => `${apiUrl}customers/${customerId}/projects/materials/details`,
  getPlantProjectDetails: (plantId: number): string => `${apiUrl}plant/${plantId}/projects/materials/details`,
  // okta
  authorize: '/okta/authorize',
  refreshToken: '/okta/refresh-token',
  logout: '/okta/logout',
  authenticate: '/authenticate',
  resetPasswordEmail: '/okta/send-reset-password-email',
  resetPasswordWithCode: '/okta/change-password-using-verification-code-from-email',
  // share widgets
  shareWidget: (widgetId: number): string => `${apiUrl}widgets/${widgetId}/shared`,
  acceptSharedWidget: (widgetId: number): string => `${apiUrl}widgets/${widgetId}/shared/accept`,
  rejectSharedWidget: (widgetId: number): string => `${apiUrl}widgets/${widgetId}/shared/reject`,
  changeNotificationStatus: `${apiUrl}notifications/states`,
  getNotifications: `${apiUrl}notifications`,
  readAllNotifications: `${apiUrl}notifications/read-all`,
  unshareWidget: (widgetId: number): string => `${apiUrl}widgets/${widgetId}/shared/unshared`,
  getSharedUsersPerWidget: (widgetId: number): string => `${apiUrl}widgets/${widgetId}/shared/users`,
  // Top 50
  getCustomersRating: (): string => `${apiUrl}customers/rating`,
  getCustomersMaterialsRating: (id?: number | string): string => `${apiUrl}customers/rating/${id}`,
  getCustomersRawMaterialsRating: (id?: number | string): string => `${apiUrl}customers/rating/${id}/subtypes`,
  getPlantsRating: (): string => `${apiUrl}plant/rating`,
  getPlantsMaterialsRating: (id?: number | string): string => `${apiUrl}plant/rating/${id}`,
  getPlantsRawMaterialsRating: (id?: number | string): string => `${apiUrl}plant/rating/${id}/subtypes`,
  getProjectsRating: (): string => `${apiUrl}projects/rating`,
  getProjectsMaterialsRating: (): string => `${apiUrl}projects/rating/types`,
  getProjectsRawMaterialsRating: (): string => `${apiUrl}projects/rating/subtypes`,
  excludeEntity: (id: number): string => `${apiUrl}customer-externals/${id}/exclude`,
  excludeEntityProjects: `${apiUrl}excluded-projects`,
  getExcludeProjects: `${apiUrl}top-projects`,
  // Materials,
  getMaterials: `${apiUrl}material-types`,
  // Ranking
  getRanking: `${apiUrl}projects-plus/shipping-log`,
  getRankingGroups: `${apiUrl}projects-plus/ranking-groups`,
  viewRankingGroups: `${apiUrl}projects-plus/ranking-groups/viewed`,
  getRankingDetails: `${apiUrl}projects-plus/detailed-ranking`,
  getDailyPerformance: `${apiUrl}projects-plus/days-ranking`,
  // Ticket Type Management
  getTicketTypeManagementList: `${apiUrl}material-types`,
  activateTicketType: (ticketTypeId: string): string => `${apiUrl}material-types/${ticketTypeId}/activate`,
  deactivateTicketType: (ticketTypeId: string): string => `${apiUrl}material-types/${ticketTypeId}/deactivate`,
  resetMaterialTypes: `${apiUrl}material-types/reset`,
  // Internal sharing
  getSharedLinksUsers: `${apiUrl}internal-sharing/users`,
  shareProjectPlusDetailsLink: `${apiUrl}internal-sharing/project-plus-details`,
  shareProjectCustomerDetailsLink: `${apiUrl}internal-sharing/project-customer-details`,
  shareProjectPlantDetailsLink: `${apiUrl}internal-sharing/project-plant-details`,
  shareCustomerDetailsLink: `${apiUrl}internal-sharing/customer-details`,
  sharePlantDetailsLink: `${apiUrl}internal-sharing/plant-details`,
  shareCustomerPlantDetailsLink: `${apiUrl}internal-sharing/customer-plant-details`,
  sharePlantCustomerDetailsLink: `${apiUrl}internal-sharing/plant-customer-details`,
  shareDetailedRankingDetails: `${apiUrl}internal-sharing/detailed-ranking-details`,
  shareDaysRankingDetails: `${apiUrl}internal-sharing/days-ranking-details`,
  shareShippingLogDetails: `${apiUrl}internal-sharing/shipping-log-details`,
  shareTruckLogDetails: `${apiUrl}internal-sharing/truck-log`,
  // Plants Health
  getPlantsHealth: `${apiUrl}plants/health`,
  postPlantsHealthAddress: (id: number): string => `${apiAdminSupplierUrlV2}plants/${id}/addresses`,
  patchPlantsHealthAddress: (id: number): string => `${apiAdminSupplierUrlV2}plants/${id}/addresses`,
  getPlantsHealthData: `${apiAdminSupplierUrlV2}plants`,
  setPlantHealthType: (plantId: number) => `${apiAdminSupplierUrlV2}plants/stationary_type/${plantId}`,
  getPlantTicketTypes: (plantId: number) => `${apiAdminSupplierUrlV2}plants/ticket_types/${plantId}`,
  updatePlantTicketTypes: (plantId: number) => `${apiAdminSupplierUrlV2}plants/ticket_types/${plantId}`,
  getPlantHealthDetails: (plantId: number) => `${apiAdminSupplierUrlV2}plants/${plantId}`,
  // other
  getRecentlyViewedList: `${apiUrl}web/recently-viewed`,
  saveCategoryToRecentlyViewedList: `${apiUrl}web/recently-viewed`,
  globalSearch: `${apiUrl}search`,
  getRecipientsStatuses: `${apiUrlV2}external-recipients/statuses`,
  setFireBaseToken: `${apiUrl}firebase/tokens/subscribe`,
  deleteFireBaseToken: `${apiUrl}firebase/tokens/unsubscribe`,
  // drivernomics
  getTruckLogList: `${apiUrl}projects-plus/truck-logs`,
  getFleetsList: `${apiUrl}projects-plus/fleets`,
  //Bid
  getBids: `${apiUrl}projects-plus/bids`,
  createBid: `${apiUrl}projects-plus/bids`,
  deleteBid: (id: number): string => `${apiUrl}projects-plus/bids/${id}`,
  editBid: (id: number): string => `${apiUrl}projects-plus/bids/${id}`,
  getBidReport: (id: number): string => `${apiUrl}projects-plus/bids/${id}/report`,
  getProjectsPlusYears: `${apiUrl}projects-plus/years`,
  getProjectsPlusMaterialTypes: `${apiUrl}projects-plus/material-types`,
  getProjectsPlusCustomers: `${apiUrl}projects-plus/customers`,
  getProjectsPlusPlants: `${apiUrl}projects-plus/plants`,
  //SuperAdmin
  getCompanies: `${apiUrl}material-producers`,
  getCompanyInfo: (id: number): string => `${apiUrl}material-producers/${id}`,
  getCompanyUsers: (id: number): string => `${apiUrl}material-producers/${id}/users`,
  createCompanyUser: (id: number): string => `${apiUrl}material-producers/${id}/users`,
  companyBlock: (id: number): string => `${apiUrl}material-producers/${id}/block`,
  companyUnblock: (id: number): string => `${apiUrl}material-producers/${id}/unblock`,
  editCompanyUser: (companyId: number, userId: number): string => `${apiUrl}material-producers/${companyId}/users/${userId}`,
  userBlock: (companyId: number, userId: number): string => `${apiUrl}material-producers/${companyId}/users/${userId}/block`,
  userUnblock: (companyId: number, userId: number): string => `${apiUrl}material-producers/${companyId}/users/${userId}/unblock`,
  userArchive: (companyId: number, userId: number): string => `${apiUrl}material-producers/${companyId}/users/${userId}/archive`,
  getAdminUsersList: `${apiUrl}users`,
  getAdminUsersInfo: (userId: number): string => `${apiUrl}users/${userId}`,
  editUsersInfo: (userId: number): string => `${apiUrl}users/${userId}`,
  // EPD
  getEpdTicketTypesList: `${apiUrl}ticket-types`,
  addEpd: `${apiUrl}declarations`,
  editEpd: (epdId: number): string => `${apiUrl}declarations/${epdId}`,
  removeEpd: (epdId: number): string => `${apiUrl}declarations/${epdId}`,
  getEpdList: `${apiUrl}declarations`,
  getMixesList: (epdId: number, plantId: number): string => `${apiUrl}mixes/${epdId}/${plantId}`,
  getMaterialsList: (plantId: number, ticketType: number): string => `${apiUrl}materials/${plantId}/${ticketType}`,
  getEnvironmentalTypes: `${apiUrl}environmental-types`,
  getMixPlantsList: `${apiUrl}plants`,
  getMixPlantInfo: (plantId: number): string => `${apiUrl}plants/${plantId}`,
  addMixForEpd: `${apiUrl}mixes`,
  removeMix: (mixId: number): string => `${apiUrl}mixes/${mixId}`,
  editMix: (mixId: number): string => `${apiUrl}mixes/${mixId}`,

  getJobSlipCustomers: `${apiAdminSupplierUrl}eticketing/job_slip_customers`,
  getJobSlipCustomer: (id: number): string => `${apiAdminSupplierUrl}eticketing/job_slip_customers/${id}`,
  getExternalCustomers: `${apiAdminSupplierUrl}eticketing/external_customers`,
  getCustomersSuggestions: `${apiAdminSupplierUrl}eticketing/customers/suggestions`,
  getHHCustomers: `${apiAdminSupplierUrl}eticketing/customers`,
  putJobSlipCustomers: (id: number): string => `${apiAdminSupplierUrl}eticketing/job_slip_customers/${id}`,
  // Calibration
  getJobSlipProjects: `${apiAdminSupplierUrl}job_slip_projects`,
  getMaterialMetrics: (jobSlipProjectId: number) => `${apiAdminSupplierUrl}job_slip_projects/${jobSlipProjectId}/material_metrics`,
  getDotAuthorities: `${apiAdminSupplierUrl}dot_authorities`,
  getConnectedDotAuthorities: `${apiAdminSupplierUrl}dot_authorities/connected`,
  getDotProjects: (dotAuthorityId: number) => `${apiAdminSupplierUrl}dot_authorities/${dotAuthorityId}/dot_projects`,
  connectCalibrationProject: (jobSlipProjectId: number) => `${apiAdminSupplierUrl}job_slip_projects/${jobSlipProjectId}/calibration`,
  editCalibrationProjectConnection: (jobSlipProjectId: number) => `${apiAdminSupplierUrl}job_slip_projects/${jobSlipProjectId}/calibration`,
  disconnectCalibrationProject: (jobSlipProjectId: number) => `${apiAdminSupplierUrl}job_slip_projects/${jobSlipProjectId}/calibration`,
  hideCalibrationProject: (jobSlipProjectId: number) => `${apiAdminSupplierUrl}job_slip_projects/${jobSlipProjectId}/calibration/dismiss`,
  showCalibrationProject: (jobSlipProjectId: number) => `${apiAdminSupplierUrl}job_slip_projects/${jobSlipProjectId}/calibration/undismiss`,
  getCalibrationJobSlipCustomers: `${apiAdminSupplierUrl}customers`,
  addCalibrationCustomerToFavorites: (customerId: number) => `${apiAdminSupplierUrl}customers/${customerId}/favorites`,
  removeCalibrationCustomerFromFavorites: (customerId: number) => `${apiAdminSupplierUrl}customers/${customerId}/favorites`,
  addDotAuthorityToFavorites: (dotAuthoritiesId: number) => `${apiAdminSupplierUrl}dot_authorities/${dotAuthoritiesId}/contractors`,
  removeDotAuthorityFromFavorites: (dotAuthoritiesId: number) => `${apiAdminSupplierUrl}dot_authorities/${dotAuthoritiesId}/contractors`,
  getCalibrationColumnSettings: `${apiUrl}calibration/columns-settings`,
  updateCalibrationColumnSettings: `${apiUrl}calibration/columns-settings`,
  resetCalibrationColumnSettings: `${apiUrl}calibration/columns-settings`,
  getSettingsCounts: `${apiAdminSupplierUrl}settings/counts`,

  // Tickets
  getSlips: `${apiAdminSupplierUrlV2}slips`,
  getSlipsFlexTablePreferences: `${apiAdminSupplierUrl}slips_flex_grid_preferences`,
  postSlipsFlexTablePreferences: `${apiAdminSupplierUrl}slips_flex_grid_preferences`,
  resetSlipsFlexTablePreferences: `${apiAdminSupplierUrl}slips_flex_grid_preferences/reset`,
  getSlipDetails: (id: number): string => `${apiAdminSupplierUrl}slips/${id}`,
  getSlipDetailsConnection: (id: number): string => `${apiAdminSupplierUrl}slips/${id}/dot_project_connection`,
  getDOTAuthorities: `${apiUrl}dot-authorities`,
  getJobSlipProject: (slipId: number) => `${apiAdminSupplierUrl}eticketing/job_slip_project/${slipId}`,
  downloadTickets: `${apiAdminSupplierUrl}reports/slip`,
  getFilterTabEntityList: `${apiUrl}flex-grid/options`,
  // Ticket monitoring
  getTicketMonitoringList: `${apiAdminSupplierUrl}dot_incidents`,
  getTicketMonitoringData: (id: number) => `${apiAdminSupplierUrl}dot_incidents/${id}`,
  updateStatusTicketMonitoring: `${apiAdminSupplierUrl}dot_incidents/bulk_update`,
  postCommentForDotIncident: (id: string) => `${apiAdminSupplierUrl}dot_incidents/${id}/comments`,
  getTicketsGroup: `${apiAdminSupplierUrl}groups`,
  getFiltersOptions: `${apiAdminSupplierUrl}dot_incidents/filter_options`,
  // IT Admin Only
  sendITAdminOnlyRequestForNewRole: `${apiUrl}it-admin-only/request-upgrade-email/send`,
  getITAdminOnlyRequestPermissionForChanging: `${apiUrl}it-admin-only/request-upgrade-email/check`,
  // Map Box
  getReversGeocoding: (longitude: number, latitude: number) => `/geocoding/v5/mapbox.places/${longitude},${latitude}.json`,
};

export default api;
